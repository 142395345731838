import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'
import { LOGIN, LOGOUT } from './actions.type'
import { SET_AUTH, PURGE_AUTH, SET_ERROR, CLEAR_ERROR } from './mutations.type'

/*
    - Axios response structure
    {
        // `data` is the response that was provided by the server
        data: {},

        // `status` is the HTTP status code from the server response
        status: 200,

        // `statusText` is the HTTP status message from the server response
        statusText: 'OK',

        // `headers` the headers that the server responded with
        headers: {},

        // `config` is the config that was provided to `axios` for the request
        config: {}
    }
    
    - API response data structure (status: 200)
    response.data = {
        success: true,
        result: {},
        message: successMessage
    }

    response.data = {
        success: false,
        message: successMessage
    }
*/

const state = {
    error: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
}

const getters = {
    currentUser(state) {
        return state.user
    },
    isAuthenticated(state) {
        return state.isAuthenticated
    }
}

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            context.commit(CLEAR_ERROR);

            ApiService.post('auth/ahubscan-login', credentials)
            .then(response => {
                if (response.data.success) {
                    context.commit(SET_AUTH, response.data.result)
                    resolve(response.data.result);
                } else {
                    context.commit(SET_ERROR, response.data.message)
                }
            })
            .catch((error) => {
                context.commit(SET_ERROR, error.message)
                reject(error)
            });
        });
    },
    [LOGOUT](context) {
        return new Promise(resolve => {
            context.commit(PURGE_AUTH);
            resolve()
        })
    },
    /*
    [CHECK_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get('auth/user')
                .then(({ response }) => {
                    context.commit(SET_AUTH, response.data)
                })
                .catch(() => {
                    // #todo SET_ERROR cannot work in multiple states
                    // context.commit(SET_ERROR, response.data.errors)
                });
                // .catch(({ response }) => {
                //     context.commit(SET_ERROR, response.data.message);
                // });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    */
}

const mutations = {
    [CLEAR_ERROR](state) {
        state.error = null
    },
    [SET_ERROR](state, error) {
        state.error = error
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true
        state.user = data.user
        state.error = null
        JwtService.saveToken(data.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.user = {}
        state.error = null
        JwtService.destroyToken()
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}